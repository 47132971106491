import React from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";


export default function AlertDialog({ isOpen, onSuccess, onClose }) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Voulez vous ajouter un tag à la zone?
      </DialogTitle>

      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Non
        </Button>
        <Button
          onClick={onSuccess}
          color="primary"
          variant="contained"
          autoFocus
        >
          Oui
        </Button>
      </DialogActions>
    </Dialog>
  );
}
AlertDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};
