import React from "react";
import PropTypes from "prop-types";
import { Alert , Snackbar } from "@mui/material";



export default function SuccessSnackbar({ isOpen, onClose, children }) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    onClose(false);
  };

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
        La zone a bien été créé
      </Alert>
    </Snackbar>
  );
}
SuccessSnackbar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
