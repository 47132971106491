import { useState } from "react";
import { Avatar, Chip, Menu, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import AlgeriaFlag from "./flags/algeria.png";
import FranceFlag from "./flags/france.png";
import SwitzerlandFlag from "./flags/switzerland.png";

export default function CountryFlag({ countryId = 1, onChangeCountry }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const selectCountry = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const changeCountry = (id) => {
    onChangeCountry && onChangeCountry(id);
    handleClose();
  };

  const flag =
    countryId === 1
      ? AlgeriaFlag
      : countryId === 2
      ? FranceFlag
      : SwitzerlandFlag;

  return (
    <>
      <Avatar
        alt="Pays"
        src={flag}
        sx={{
          width: "48px",
          height: "48px",
          border: "solid 1px #fff",
          bgcolor: "#fff",
        }}
        onClick={handleOpen}
      />

      <Menu
        autoFocus={false}
        anchorEl={anchorEl}
        open={selectCountry}
        onClose={handleClose}
        sx={{ mt: 2 }}
      >
        <MenuItem onClick={() => changeCountry(1)}>
          <Avatar
            alt="Algérie"
            src={AlgeriaFlag}
            sx={{ width: "48px", height: "48px", mr: 2 }}
          />
          <Chip sx={{ fontSize: "20px", p: 1 }} label="Algérie" />
        </MenuItem>

        <MenuItem onClick={() => changeCountry(2)}>
          <Avatar
            alt="France"
            src={FranceFlag}
            sx={{ width: "48px", height: "48px", mr: 2 }}
          />
          <Chip sx={{ fontSize: "20px", p: 1 }} label="France" />
        </MenuItem>

        <MenuItem onClick={() => changeCountry(3)}>
          <Avatar
            alt="Suisse"
            src={SwitzerlandFlag}
            sx={{ width: "48px", height: "48px", mr: 2 }}
          />
          <Chip sx={{ fontSize: "20px", p: 1 }} label="  Suisse" />
        </MenuItem>
      </Menu>
    </>
  );
}
CountryFlag.propTypes = {
  countryId: PropTypes.number,
  onChangeCountry: PropTypes.func,
};
