import { ZoningBase } from "@mps-dz/react-zoning";
import {
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuIcon from "@mui/icons-material/Menu";
import AlertDialog from "./components/AlertDialog";
import FormDialog from "./components/FormDialog";
import SuccessSnackbar from "./components/SuccessSnackbar";
import CountryFlag from "./components/CountryFlag";

const mapOptions = {
  mapTypeControlOptions: {
    style: 1,
    position: 6,
  },
};

const defaultZoneConfig = {
  name: "",
  color: "#ff0000",
  areas: [],
  id: 0,
};

const getStorageCountry = () => {
  const storage = window.localStorage.getItem("countryId");
  return storage ? JSON.parse(storage) : 1;
};

const setStorageCountry = (country) => {
  window.localStorage.setItem("countryId", JSON.stringify(country));
};

function App() {
  const [zones, setZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState(defaultZoneConfig);
  const [submittedZone, setSubmittedZone] = useState({});

  const [isZoningDrawerOpen, setIsZoningDrawerOpen] = useState(false);
  const [isDialogopen, setIsDialogOpen] = useState(false);
  const [isTagFormOpen, setIsTagFormOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const [selectedCountry] = useState(getStorageCountry);

  const onChangeCountry = (countryId) => {
    setStorageCountry(countryId);
    window.location.assign(window.location);
  };

  const selectZone = (zone, isReadOnly = false) => {
    setSelectedZone(JSON.parse(JSON.stringify({ ...zone, isReadOnly })));
  };
  const addAndUpdateZone = ({ newZone }) => {
    setZones([...zones.filter((zone) => zone.id !== newZone.id), newZone]);
  };

  const deleteZone = ({ zoneId }) => {
    setZones(zones.filter((zone) => zone.id !== zoneId));
  };

  const newZone = () => {
    selectZone({
      ...defaultZoneConfig,
      id: Math.random().toString(16).slice(2),
    });
  };

  const toggleDrawer = () => {
    setIsZoningDrawerOpen(!isZoningDrawerOpen);
  };

  const save = async (newZone) => {
    setSubmittedZone({
      ...newZone,
      id: selectedZone.id,
      name: selectedZone.name,
    });
    setIsDialogOpen(true);
  };

  return (
    <div style={{ height: "100vh" }}>
      <CssBaseline />
      <Drawer open={isZoningDrawerOpen} onClose={toggleDrawer}>
        <Box sx={{ width: 350 }} onClick={toggleDrawer}>
          <ListItem
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" component="h2">
              Zones
            </Typography>
            <IconButton aria-label="add" onClick={newZone}>
              <AddIcon />
            </IconButton>
          </ListItem>
          <Divider />
          <List>
            {zones.map((zone) => (
              <ListItem
                selected={zone.id === selectedZone.id}
                button
                key={zone.id}
                onClick={() => selectZone(zone, true)}
              >
                <IconButton
                  aria-label="delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteZone({ zoneId: zone.id });
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
                <ListItemText primary={zone.name || "Nouvelle zone"} />
                <IconButton
                  aria-label="delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    selectZone(zone, false);
                    toggleDrawer();
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box
        sx={{
          display: "flex",
          position: "absolute",
          top: "8px",
          left: "8px",
          zIndex: "2",
          gap: 2,
        }}
      >
        <IconButton
          sx={{
            background: "#fff",
            color: "#000",
            height: "48px",
            width: "48px",
            boxShadow:
              "0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
            "&:hover": {
              background: "#fff",
              color: "#000",
            },
          }}
          onClick={toggleDrawer}
          aria-label="Menu"
        >
          <MenuIcon />
        </IconButton>
        <CountryFlag
          countryId={selectedCountry}
          onChangeCountry={onChangeCountry}
        />
      </Box>

      <AlertDialog
        isOpen={isDialogopen}
        onSuccess={() => {
          setIsDialogOpen(false);
          setIsTagFormOpen(true);
        }}
        onClose={() => {
          setIsDialogOpen(false);
          addAndUpdateZone({ newZone: submittedZone });
          setIsSnackbarOpen(true);
          //create new zone after submitting the previous one
          newZone();
        }}
      />

      <FormDialog
        defaultInput={submittedZone.name}
        isOpen={isTagFormOpen}
        onClose={() => {
          setIsTagFormOpen(false);
          addAndUpdateZone({ newZone: submittedZone });
          setIsSnackbarOpen(true);
          //create new zone after submitting the previous one
          newZone();
        }}
        onSuccess={(inputValue) => {
          setIsTagFormOpen(false);
          addAndUpdateZone({ newZone: { ...submittedZone, name: inputValue } });
          setIsSnackbarOpen(true);
          //create new zone after submitting the previous one
          newZone();
        }}
      />

      <SuccessSnackbar
        isOpen={isSnackbarOpen}
        onClose={() => {
          setIsSnackbarOpen(false);
        }}
      />

      <ZoningBase
        isOpen
        mapOptions={mapOptions}
        keys={{
          mps: "test",
          googleMap: "AIzaSyA2Z59ck103iSFvsfSt9aOnqy5MXum0EMo",
        }}
        isReadOnly={selectedZone.isReadOnly}
        initArea={selectedZone.areas}
        defaultColor={selectedZone.color}
        onSubmit={save}
        countryId={selectedCountry}
      />
    </div>
  );
}

export default App;
