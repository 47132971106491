import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

export default function FormDialog({
  isOpen,
  onSuccess,
  onClose,
  defaultInput
}) {
  const [inputValue, setInputValue] = useState(defaultInput);

  useEffect(() => {
    setInputValue(defaultInput);
  }, [defaultInput]);

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Ajouter vos tags</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Veuillez svp ajouter le nom de la zone :
        </DialogContentText>
        <form noValidate autoComplete="off" onSubmit={e => e.preventDefault()}>
          <TextField
            autoFocus
            margin="dense"
            value={inputValue}
            onChange={e => {
              setInputValue(e.target.value);
            }}
            id="name"
            label="Nom de la zone"
            fullWidth
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Annuler
        </Button>
        <Button
          onClick={() => {
            onSuccess(inputValue);
          }}
          color="primary"
          variant="contained"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
FormDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  defaultInput: PropTypes.string
};
FormDialog.defaultProps = {
  defaultInput: ""
};
